<template>
  <div class="relative flex flex-col pr-12 gap-y-1">
    <span v-if="!adressenParsed?.length" class="text-red-500">Geen adressen ?!</span>
    <GoogleLink v-else-if="!store.getters.isKantoorPermission" :adres="adressenParsed" :show-geocode-status="showGeocodeStatus">
      <i class="fas fa-directions text-xl absolute top-1 right-1"></i>
    </GoogleLink>
    <GoogleLink
      v-for="(adres, index) in adressenParsed"
      :key="adres.adres + index"
      :icon="adres.is_luchthaven ? 'fas fa-plane mr-1' : 'fas fa-map-marker-alt mr-1'"
      :show-geocode-status="showGeocodeStatus"
      :adres="adres"
      class="inline-block text-sm mr-auto pt-0"
    />
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import GoogleLink from '@/components/Google/Link.vue'
import useParsedAdressen from '@/hooks/useParsedAdressen'
import { useStore } from 'vuex'

const props = defineProps({
  showGeocodeStatus: Boolean,
  adressen: {
    type: [Array, String],
    default() { return [] },
    required: true,
  },
})
const store = useStore()

const adressenParsed = useParsedAdressen(props)
</script>
